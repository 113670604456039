import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class LoggerService {

    debug(message?: any, ...optionalParams: any[]) {
        if(!environment.production) {
            console.debug(message, optionalParams);
        }
    }

    debugError(message?: any, ...optionalParams: any[]) {
        if(!environment.production) {
            console.error(message, optionalParams);
        }
    }

    info(message?: any, ...optionalParams: any[]) {
        console.info(message, optionalParams);
    }

    error(message?: any, ...optionalParams: any[]) {
        console.error(message, optionalParams);
    }

    warn(message?: any, ...optionalParams: any[]) {
        console.warn(message, optionalParams);
    }
}
import { Component, HostListener, OnDestroy } from '@angular/core';
import { DeviceDetectorService } from '@hss-m/uikit-common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnDestroy {
    progressSpinner = true;
    constructor() {
        this.onResize();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        const bodyTag = document.getElementsByTagName('body')[0];
        const breakpoint = DeviceDetectorService.deviceBreakpoint();
        bodyTag.className = `${breakpoint}`;
    }

    ngOnInit() { }

    onActivate(e) {
      this.progressSpinner = false;
    }

    ngOnDestroy(): void {
    }
}

import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { Route } from '@angular/router';
import { HAuthenticationGuard } from './modules/h-auth/h-auth.guard';

export const routes: Route[] = [
    {
        path: '',
        children: [
            {
                path: '', redirectTo: 'app', pathMatch: 'full'
            },
            {
                path: 'auth',
                loadChildren: () => import('./modules/h-auth/h-auth.module').then(m => m.HAuthModule)
            },
            {
                path: 'app',
                loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
                canActivate: [HAuthenticationGuard]
            },
            {
                path: 'public',
                loadChildren: () => import('./pages/public/public.module').then(m => m.PublicModule),
            }
        ]
    },
    {
        path: "**",
        redirectTo: "app"
    }
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })
    ],
    exports: [RouterModule]
})

export class AppRoutingModule { }
import { NgModule } from '@angular/core';
import { ToastModule } from 'primeng/toast';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { APP_INTERCEPTORS } from './interceptors';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { connectFirestoreEmulator, getFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { connectStorageEmulator, getStorage } from '@angular/fire/storage';
import { LOCAL_STORAGE_CONFIG, LocalStorageConfig } from '@hss-m/uikit-common';

export const LOCAL_STORAGE: LocalStorageConfig = {
    APP_BASE_URL: 'terra-base-url',
    AUTH_TOKEN: 'terra-secure-token',
    TABLE_COLUMNS_CONFIG_LSC_KEY: 'terra_tables_config'
}

@NgModule({
    imports: [
        FormsModule,
        ToastModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => {
            const auth = getAuth();
            if (environment.useEmulators) {
                console.log("Using Firebase Auth Emulator");
                connectAuthEmulator(auth, 'http://127.0.0.1:9099', { disableWarnings: true });

                const storage = getStorage();
                console.log("Using Firebase Storage Emulator");
                connectStorageEmulator(storage, '127.0.0.1', 9199);
                const firestore = getFirestore();

                console.log("Using Firestore DB Emulator");
                connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
            }
            return auth;
        }),
        { provide: LOCAL_STORAGE_CONFIG, useValue: LOCAL_STORAGE },
        MessageService, 
        DialogService,
         ...APP_INTERCEPTORS
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export const environment = {
  production: true,
  mapId: "32d22c4d0175315a",
  firebase: {
    projectId: "terra-compliance-uat",
    apiKey: "AIzaSyAF2IAJR0bo8A68edHCUMXJm19n3wm7gbI",
    authDomain: "terra-compliance-uat.firebaseapp.com",
    storageBucket: "terra-compliance-uat.appspot.com",
    messagingSenderId: "343125818645",
    appId: "1:343125818645:web:e538d6e0ac6c778a613d1c",
    measurementId: "G-PSDMWLLPLE"
  },
  useEmulators: false,
  base_url: 'api',
  disableCacheGlobally: false
};

import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './h-auth.service';
import { RouteService } from '@hss-m/uikit-common';

@Injectable({
  providedIn: 'root'
})
export class HAuthenticationGuard  {

  constructor(
    private authService: AuthService,
    private routeService: RouteService
  ) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isLoggedIn().then(isLoggedIn => {
      if(!isLoggedIn) {
        this.routeService.navigate('auth/signin');        
      }
      return isLoggedIn;
    });
  }
}
